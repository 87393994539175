//

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import MainLayout from '../components/MainLayout';

import SubpageHeader from '../components/SubpageHeader';
import CallToAction from '../components/CallToAction';

import createMetaData from '../utils/createMetaData';

const PageThanks = () => {
	const { pageThanksJson: json } = useStaticQuery(graphql`
		query {
			pageThanksJson {
				meta_description
				meta_keywords
				meta_title
				permalink
				title
			}
		}
	`);
	const { site, page, seo } = createMetaData(json);
	return (
		<MainLayout site={site} page={page} seo={seo}>
			<SubpageHeader page={page} />
			<section id='thanks-section'>
				<div className='container'>
					<div className='thanks-outer'>
						<div className='thanks-inner'>
							<h4>Thank you for your interest</h4>
							<p>I will reply shortly</p>
						</div>
					</div>
				</div>
			</section>
			<CallToAction />
		</MainLayout>
	);
};

export default PageThanks;
