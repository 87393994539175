
import React from 'react';

import { Link, graphql, useStaticQuery } from 'gatsby';

const CallToAction = () => {
	const { contentCallToActionJson: content } = useStaticQuery(graphql`
		query {
			contentCallToActionJson {
				tell_me
				text
				title
			}
		}
	`);

	return (
		<section id='call-to-action'>
			<div className='container'>
				<h2>{content.title}</h2>
				<p>{content.text}</p>
				<Link className='call-to-action-button' to='/contact'>
					{content.tell_me}
				</Link>
			</div>
		</section>
	);
};

export default CallToAction;
